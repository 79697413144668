// plugins/axios.ts
import LRU from 'lru-cache'
import { ERROR } from '~/constants/common'

const cache = new LRU({
  max: 500,
  ttl: 1000 * 30 // 30s
})

export default defineNuxtPlugin((nuxtApp) => {
  const { $axios, $ua, ssrContext } = nuxtApp as Record<string, any>

  // Thiết lập header cho Axios
  if ($axios && $ua) {
    $axios.setHeader('os', $ua.os())
    $axios.setHeader('device', $ua.deviceType())
    $axios.setHeader('browser', $ua.browser())
  }

  if (ssrContext) {
    $axios.setBaseURL(ssrContext?.runtimeConfig?.public?.API_BASE_URL)
  } else {
    $axios.setBaseURL(window.location.origin)
  }

  $axios.interceptors.request.use((config: { url: any; cacheSsr: any }) => {
    const cacheKey = config.url
    if (ssrContext) {
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        console.log('Có data cached', cacheKey);
        // console.log('Có data cached', { cachedData });
        return Promise.resolve({ data: cachedData, time: '' })
      }
    }

    return config
  })

  $axios.interceptors.response.use((response: { config: { url: string, headers: any }; data: unknown }) => {
    const cacheKey = response.config.url

    if (ssrContext && response.config.headers) {
      cache.set(cacheKey, response.data)
      const headers = response.config.headers

      const requestInfor = {
        IP: headers['x-forwarded-for'] || 'IP cannot detected',
        country: headers['cf-ipcountry'] || 'country cannot detected',
        os: headers.os,
        device: headers.device,
        browser: headers.browser
      }

      console.log(`${response.config.url} - ${requestInfor.country} - ${requestInfor.IP} - ${requestInfor.device} - ${requestInfor.os} - ${requestInfor.browser}`)
    }
    return response
  }, (error: { config: { bypassErrorToast: any }; response: { data: { message: any } } }) => {
    if (error.config) {
      // console.log('axios_error', error);
      return Promise.resolve(error.config)
    }
    
    

    const nuxtApp = useNuxtApp()
    if (!error?.config.bypassErrorToast) {
      nuxtApp.$alertMsg('error', error?.response?.data?.message || ERROR)
    }
    throw error
  })
})
